import AccordionBody from "components/Accordion/AccordionBody"
import AccordionHeader from "components/Accordion/AccordionHeader"
import AccordionLayout from "components/Accordion/AccordionLayout"
import BorderedButton from "components/Button/BorderedButton"
import BorderlessButton from "components/Button/BorderlessButton"
import FillButton from "components/Button/FillButton"
import Cart from "components/Cart"
import SubmodelSummary from "components/Cart/SubmodelSummary"
import Header from "components/Header"
import AvailabilityInput from "components/Input/AvailabilityInput"
import PageModelIterator from "components/Iterators/PageModelIterator"
import LinearPreview from "components/LinearPreview"
import Submodel from "components/Submodel"
import { BUILDER_CONFIG, BUILDER_PAGES, CONFIG_CUSTOMIZATION, CUSTOMIZATION_NAVIGATION, DEFN_BUILDER_PAGES, FRMDATA_SMID, MODEL_DYNAMIC_FIELDS, PAGE_AUTO_ADVANCE, PAGE_FORM_DATA, PAGE_ID, RUNTIME_INSTANCE_INSTANCE_ID } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import NodeHelper from "helpers/NodeHelper"
import { FaRegTrashAlt } from "react-icons/fa"
import { toast } from "react-toastify"
import Utils from "Services/Utils"
import { TRuntimeInstance} from "types"

const GuidedSellingLayout = () => {
    const {
        dataLoading,
        deleteInstance,
        setActivePage,
        uiDefn,
        activePage,
        getInstances,
        theme,
        specs,
        builderSpec,
    } = useAppContext()

    const activePageIndex = uiDefn?.[DEFN_BUILDER_PAGES][BUILDER_PAGES].findIndex(p => p[PAGE_ID] === activePage?.[PAGE_ID]) ?? 0

    const isLinear = builderSpec?.[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_NAVIGATION] === "linear"


    const handleNextPage = (skip: boolean = false) => {
        if(!skip && isLinear && activePage?.[PAGE_FORM_DATA].some((builderSubmodel) => {
            const _instances = getInstances(builderSubmodel[FRMDATA_SMID]) ?? []
            return _instances.some((instance) => !NodeHelper.checkIfSubmodelIsDoneByRuntimeSpec(instance))
        })) return toast.error("Complete all the required fields")

        if(!uiDefn) return console.error("UI Defn not found")
        
        if(uiDefn?.[DEFN_BUILDER_PAGES][BUILDER_PAGES].length > activePageIndex) 
            setActivePage(uiDefn?.[DEFN_BUILDER_PAGES][BUILDER_PAGES]
            .find((page, index) => page[PAGE_FORM_DATA].some((builderSubmodel) => {
                if(index <= activePageIndex) return false
                const instances = getInstances(builderSubmodel[FRMDATA_SMID])
                return instances && instances?.length > 0
            })
        ))
    }


    const handlePrevPage = () => {
        if(!uiDefn) return console.error("UI Defn not found")
        
        if(uiDefn?.[DEFN_BUILDER_PAGES][BUILDER_PAGES].length > activePageIndex){ 
            const pagesLength = uiDefn?.[DEFN_BUILDER_PAGES][BUILDER_PAGES].length
            
            setActivePage(uiDefn?.[DEFN_BUILDER_PAGES][BUILDER_PAGES]
                .findLast((page, index) => page[PAGE_FORM_DATA].some((builderSubmodel) => {
                    if(index >= activePageIndex) return false
                    const instances = getInstances(builderSubmodel[FRMDATA_SMID])
                    return instances && instances?.length > 0
                })
            ) ?? uiDefn?.[DEFN_BUILDER_PAGES][BUILDER_PAGES][0])
        }
    }

    // const isPageRequired = activePage?.[PAGE_FORM_DATA].some((builderSubmodel) => {
    //     const modelSubmodel = specs[builderSubmodel[FRMDATA_SMID]]
    //     if(!modelSubmodel) return false

    //     const instances = getInstances(builderSubmodel[FRMDATA_SMID])
    //     if(instances?.length === 0) return false

    //     return instances?.some(instance => NodeHelper.checkIfSubmodelIsRequiredByRuntimeSpec(instance))
    // })

    const ifAnyVisibleCategory = activePage?.[PAGE_FORM_DATA]?.some((uiModel, modelKey) => {
        const _instances = getInstances(uiModel[FRMDATA_SMID]) ?? []
        return _instances.some((instance, key) => {
            const visibleNodes = NodeHelper.filterVisibleNodesUsingRuntime(instance[MODEL_DYNAMIC_FIELDS])
            return visibleNodes.visibleCategoryIds.length > 0
        })
    })

    return (
        <div className="sb3-flex sb3-justify-around">
            <div className={`sb3-space-y-2 sb3-w-full sb3-max-w-4xl sb3-pt-6 sb3-py-3 sb3-px-3`}>
                {
                    Utils.checkIfClientWebsite() &&
                    <div className="sb3-w-full sb3-flex sb3-justify-end"><AvailabilityInput/></div>
                }
                <PageModelIterator>
                    {({
                        visibleFields,
                        visibleConnectors,
                        visibleCategories,
                        disableInstantiation,
                        title,
                        isNumbered,
                        number,
                        instances,
                        _minInstantiation,
                        _maxInstantiation,
                        isInstantiable,
                        instance,
                        model,
                        uiModel
                    }) => (
                            <div key={number} className={`sb3-space-y-1 sb3-bg-white sb3-rounded-none sb3-pb-5 ${Utils.isConfigurator(theme) ? "sb3-border sb3-py-3 sb3-px-3" : "sb3-border-b sb3-border-gray sb3-last:border-0"}`}>
                                <AccordionLayout disabled={disableInstantiation} initialState={true}>
                                    {({isOpen, ...provided}) => (
                                    <>
                                        <AccordionHeader {...provided.getHeaderProps()}>
                                            <span className="sb3-flex sb3-justify-between sb3-items-center">
                                                <Header 
                                                    title={title}
                                                    number={isNumbered ? number: undefined}
                                                    className={!Utils.isConfigurator(theme) ? "!sb3-text-xl" : ""}
                                                />
                                                <div className="sb3-flex sb3-space-x-2 sb3-items-center">
                                                   
                                                    {
                                                        (!disableInstantiation && isInstantiable) && 
                                                        <BorderlessButton 
                                                        className="sb3-min-w-min"
                                                        disabled = {instances.length <= _minInstantiation || dataLoading}
                                                        onClick = {() => deleteInstance((instance as TRuntimeInstance)[RUNTIME_INSTANCE_INSTANCE_ID])}
                                                        >
                                                            <FaRegTrashAlt className={`${(instances.length > _minInstantiation && !dataLoading) ? "sb3-text-black" :  "sb3-text-gray-light"}`}/>
                                                        </BorderlessButton>
                                                    }
                                                </div>
                                                
                                            </span>
                                        </AccordionHeader>
                                    
                                        <AccordionBody {...provided.getBodyProps()} 
                                            showOnClose={true}
                                            className={(_minInstantiation !== _maxInstantiation && isOpen) ? "sb3-bg-gray-100 sb3-border sb3-p-3" : ""}>
                                            {
                                                isOpen ?
                                                <Submodel 
                                                    visibleFields = {visibleFields} 
                                                    visibleConnectors = {visibleConnectors} 
                                                    visibleCategories = {visibleCategories} 
                                                    instance = {instance} 
                                                    model={model} 
                                                    uiModel={uiModel}
                                                />
                                                :
                                                <SubmodelSummary categoryIds = {visibleCategories} />
                                            }
                                        </AccordionBody> 
                                    </>
                                    )}
                                </AccordionLayout>
                            </div>
                    )}
                </PageModelIterator>
                {
                    !activePage?.[PAGE_AUTO_ADVANCE] && 
                    <div className="sb3-max-w-full sb3-m-auto sb3-border-t sb3-sticky sb3-flex sb3-justify-between sb3-bottom-0 sb3-left-0 sb3-bg-white sb3-py-2 sb3-w-full">
                        { 
                            activePageIndex !== 0 ?
                            <BorderedButton 
                                className="sb3-px-5 sb3-h-full !sb3-bg-gray !sb3-border-gray"
                                onClick={handlePrevPage}
                            >BACK</BorderedButton>
                            : <span></span>
                        }
                        <div className="sb3-max-w-4xl sb3-flex sb3-justify-end sb3-items-center sb3-space-x-2">
                            <BorderedButton 
                                className="sb3-px-5 sb3-h-full !sb3-bg-gray !sb3-border-gray"
                                onClick={() => handleNextPage(true)}
                            >SKIP</BorderedButton>

                            <FillButton 
                                className="sb3-px-5 sb3-h-full"
                                onClick={() => handleNextPage(true)}
                            >CONTINUE</FillButton>
                        </div>
                    </div>
                }
            </div>

            {
                (Utils.isLinear(theme) || (ifAnyVisibleCategory && Utils.isConfigurator(theme))) && 
                <div className={`${Utils.isLinear(theme) ? 'sb3-bg-gray sb3-px-3' : 'sb3-pt-6 sb3-w-[320px]'} sb3-py-3 sb3-max-h-full sb3-max-w-[320px]`}>
                    <Cart/>
                </div>
            }
            
        </div>
    )
}

export default GuidedSellingLayout