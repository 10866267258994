import React from "react";
import FillButton from "./Button/FillButton";
import BorderedButton from "./Button/BorderedButton";
import {IoWarningOutline} from 'react-icons/io5'
import ModalContainer from "./Modal/ModalContainer";
import ModalHeader from "./Modal/ModalHeader";
import ModalBody from "./Modal/ModalBody";
import { TdisabledModal } from "types/contexts/AppContext";
import { useAppContext } from "contexts/AppContext";

type MyProps = {
  showModal: TdisabledModal|null,
  closeModal: () => void
  onConfirm: () => void
  loading: boolean
}

const DisabledModal = ({ showModal, closeModal, onConfirm, loading }: MyProps) => {
  const {
    fieldIdNameMap
  } = useAppContext()

  return (<ModalContainer
    isOpen={!!showModal}
    setOpen={closeModal}
    className="sb3-rounded-none"
  >
    <ModalHeader>
      <div className=" sb3-py-2">
        <h5 className="sb3-text-xl sb3-font-bold">Switch to &quot;{showModal?.name}&quot;</h5> 
        {/* <span>{totalProducts} {totalProducts === 1 ? "Product" : "Products"} found</span> */}
      </div>
    </ModalHeader>
    <ModalBody>
      <div className="sb3-space-y-2">
      <div className="sb3-bg-orange-50 sb3-flex sb3-space-x-3 sb3-items-center sb3-p-2 sb3-px-3 sb3-rounded ">
        <IoWarningOutline className="sb3-text-orange-500 sb3-text-3xl"/>
        <p>
          This action will unselect options that conflicts with this value
        </p>
      </div>
        <p className="sb3-text-xs sb3-text-gray-400">
            Some of the selected values will reset          
        </p>
        <h3 className="sb3-font-semibold sb3-text-sm">
          Conflicted Models
        </h3>
        <div className="sb3-text-sm">
            {
                showModal?.fields && Object.entries(showModal?.fields).map((field, key) => (
                  <div key = {key} className="even:sb3-bg-gray-100 sb3-w-full sb3-flex sb3-justify-between sb3-p-1.5">
                    <span>{fieldIdNameMap && Object.entries(fieldIdNameMap)?.find((val) => val[0].includes(field[0]))?.[1]}</span>
                    <span>{`${field[1]}`}</span>
                  </div>
                ))
            }
        </div>
      <center><p>Would you like to proceed?</p></center>
      <div className="sb3-flex sb3-justify-center sb3-space-x-3">
        <BorderedButton onClick={() => closeModal()}>Cancel</BorderedButton>
        <FillButton 
            onClick={() => onConfirm()}
            disabledClassName = "!sb3-border-red-200 !sb3-bg-red-200"
            disabled = {loading}
        >Proceed</FillButton>
      </div>
      </div>
    </ModalBody>
  </ModalContainer>
  )
}

export default DisabledModal