/**
 * Enviroment variables and their defaults
 */
// const ISR_BUILDER_API_URL = process.env.REACT_APP_ISR_BUILDER_API_URL ?? "https://isr-api-dev.compatio.net/";
// const ISR_BUILDER_API_URL = process.env.REACT_APP_ISR_BUILDER_API_URL ?? "https://isr-api-sbp-test.compatio.net/";
// const ISR_BUILDER_API_URL = process.env.REACT_APP_ISR_BUILDER_API_URL ?? "https://isr-api-applico.compatio.net/";

import { URL_ENV_MAP } from "constants/constants";

// const ISR_BUILDER_API_URL = process.env.REACT_APP_ISR_BUILDER_API_URL ?? "https://isr-api-test.compatio.net/"; // test equity
const ISR_BUILDER_API_URL = process.env.REACT_APP_ISR_BUILDER_API_URL ?? "https://isr-api-test.compatio.net/"; // prod galco

// const ISR_BUILDER_API_URL = process.env.REACT_APP_ISR_BUILDER_API_URL ?? "https://isr-api-qa.compatio.net/"; // qa galco

const ISR_PROJECT_ID = process.env.REACT_APP_ISR_PROJECT_ID ?? "c9bf21f6402811ef9fb50eae795c602d"; // prod galco

// client config passed as global variables

const CLIENT_MAGENTO_GRAPHQL_SUFFIX = process.env.REACT_APP_CLIENT_MAGENTO_GRAPHQL_SUFFIX ?? window.compatioConfig?.magento?.apiEndpointUrl;
const CLIENT_MAGENTO_BASE_URL = process.env.REACT_APP_CLIENT_MAGENTO_BASE_URL ?? window.compatioConfig?.magento?.baseUrl;
const CLIENT_MAGENTO_API_END_POINT = process.env.REACT_APP_CLIENT_MAGENTO_API_END_POINT ?? window.compatioConfig?.magento?.apiEndpointUrl;
const CLIENT_MAGENTO_TARGET_GRAPH = process.env.REACT_APP_CLIENT_MAGENTO_TARGET_GRAPH ?? window.compatioConfig?.magento?.targetGraph;
const CLIENT_MAGENTO_URL_SUFFIX = process.env.REACT_APP_CLIENT_MAGENTO_URL_SUFFIX ?? window.compatioConfig?.magento?.urlSuffix;
const MERCHANT_KEY = process.env.REACT_APP_MERCHANT_KEY ?? "gms2ESRffsNRhpIlulnu";

const MAX_CATEGORIES = process.env.REACT_APP_MAX_CATEGORIES ? +process.env.REACT_APP_MAX_CATEGORIES : 10;
const THEME_FROM_BUILDER = process.env.REACT_APP_THEME_FROM_BUILDER ? process.env.REACT_APP_THEME_FROM_BUILDER === true : true;
const ANALYTICS_MODE = process.env.REACT_APP_ANALYTICS_MODE ? process.env.REACT_APP_ANALYTICS_MODE === true : false;
const ANALYTICS_URL = process.env.REACT_APP_ANALYTICS_URL ?? "https://bdgzan43s2.execute-api.us-east-2.amazonaws.com/analytics-v2-prod";
// const ANALYTICS_URL = process.env.REACT_APP_ANALYTICS_URL ?? "https://anaytics-test.com";
const ANALYTICS_ENV = process.env.REACT_APP_ANALYTICS_ENV ?? "test"
const DEVELOPMENT_ENV = process.env.REACT_APP_DEVELOPMENT_ENV ?? "prod"


const exports = {
  ISR_BUILDER_API_URL,
  CLIENT_MAGENTO_GRAPHQL_SUFFIX,
  CLIENT_MAGENTO_API_END_POINT,
  CLIENT_MAGENTO_BASE_URL,
  CLIENT_MAGENTO_TARGET_GRAPH,
  MERCHANT_KEY,
  MAX_CATEGORIES,
  CLIENT_MAGENTO_URL_SUFFIX,
  THEME_FROM_BUILDER,
  ANALYTICS_MODE,
  ANALYTICS_URL,
  ISR_PROJECT_ID,
  ANALYTICS_ENV,
  DEVELOPMENT_ENV
};

export default exports
