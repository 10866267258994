import { get as _get } from 'lodash';
import AppSettings from 'Settings/AppSettings'
/**
 * This object encapsulates the interaction with the Compatio API
 */
type PriceReturnType = {
  [x: string]: {
    "price": string,
    "avail": number,
    "lead_time": string,
    "as_low_as": string,
    "show_price": string
  }
}

const priceApiStatus = _get(
  window,
  'compatioConfig.compatibleProducts.priceEngineApi'
) as boolean|undefined ?? true;

let PriceEngine = {
  /**
   * An object that stores a cache of all requests to retrieve data from the Compatio API.
   * The reason we're doing this is because a user could trigger an API call multiple times before the
   * initial request is returned from the server, so by caching the initial request for the product data,
   * we can avoid additional requests
   */
  categoryRetrievalPromiseCache: {},

  // TODO: Update this to the final API url (some subdomain of compat.io)
  apiEndpoint: 'https://mcstaging2.galco.com/dynamicdata/api/getdata/',

  /**
   * Reset object to its initial state. Useful for automated testing.
   */
  reset: function() {
    this.categoryRetrievalPromiseCache = {};
  },

  /**
   * Retrieve Compatio compatibility data for a specific merchantProductSku
   *
   * @param merchantProductSku
   * @returns {Promise<any>}
   */

  // priceEngineProcess()
  async priceEngineProcess(priceEngingPostCall: string): Promise<PriceReturnType|'404'> {
    //  console.log(priceEngingPostCall,'priceEngingPostCall' )
    if (!priceApiStatus === true || !AppSettings.CLIENT_MAGENTO_BASE_URL) return '404'

      const GRAPHQL_BODY: RequestInit = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            Accept: '*/*',
            Authority: 'mcstaging2.galco.com',
            'X-Requested-With': 'XMLHttpRequest',
          },
          body: priceEngingPostCall,
          redirect: 'follow',
      };

      try {
        const response = await fetch(
          `${AppSettings.CLIENT_MAGENTO_BASE_URL}dynamicdata/api/getdata/`,
          GRAPHQL_BODY
        );
        return await response.json(); // Return the parsed JSON response
      } catch (error) {
        return '404';
      }
  }
};

// export to the global namespace to allow methods to be overridden from code that doesn't import this class directly

export default PriceEngine;
