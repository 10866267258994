'use client'

import { TButtonProps } from "types/components/Button";
import React from "react";
import { useAppContext } from "contexts/AppContext";
import { BUILDER_CONFIG, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, THEME_FILL_BUTTON_BG, THEME_FILL_BUTTON_CLR } from "constants/constants";

const FillButton = ({
  type = "button",
  border = "2",
  buttonStyle = "primary",
  id = "",
  onClick,
  disabled = false,
  className = "",
  disabledClassName = "",
  children,
  color,
  ...rest
}: TButtonProps) => {
  const {
    builderSpec
  } = useAppContext()
  let themeClasses = `sb3-border-primary sb3-bg-primary hover:sb3-bg-primary-hover hover:sb3-border-primary-hover disabled:sb3-bg-primary-disabled disabled:sb3-border-primary-disabled
    hover:disabled:sb3-bg-primary-disabled hover:disabled:sb3-border-primary-disabled
    hover:sb3-bg-primary hover:sb3-border-primary hover:sb3-text-white focus:sb3-text-white focus:sb3-bg-primary focus:sb3-border-primary focus:sb3-outline-none 
  `

  if(buttonStyle === "danger") 
    themeClasses = `sb3-border-danger sb3-bg-danger hover:sb3-bg-danger-hover hover:sb3-border-danger-hover 
                    disabled:sb3-bg-danger-disabled disabled:sb3-border-danger-disabled
                    hover:disabled:sb3-bg-danger-disabled hover:disabled:sb3-border-danger-disabled
                    hover:!sb3-bg-danger hover:!sb3-border-danger hover:!sb3-text-white focus:!sb3-text-white focus:!sb3-bg-danger focus:!sb3-border-danger`

  const styles: any = {}
  if(buttonStyle === "primary"){
    if(builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]) {
      styles.backgroundColor = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]
      styles.borderColor = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]
      styles.color = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_CLR]
    }
  }
  
  return (
    <div>
      <div
        id={id}
        // type={type}
        onClick={onClick}
        disabled={disabled}
        className={` ${disabled && disabledClassName} ${themeClasses} ${className}
          sb3-border sb3-rounded-none  sb3-box-border 
          sb3-cursor-pointer sb3-font-noto-sans sb3-text-sm
          sb3-leading-5 sb3-min-h-[36px] sb3-m-0 focus:sb3-outline-none sb3-p-2 sb3-py-1 !sb3-min-w-[92px]
          sb3-flex sb3-items-center sb3-justify-center
          sb3-relative sb3-text-center sb3-no-underline sb3-touch-manipulation sb3-select-none sb3-font-normal sb3-text-white
        `}
        style={styles}
        {...rest}
      >
        {children}
      </div>
    </div>
  );
};


export default FillButton;
