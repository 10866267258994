import { useAppContext } from "contexts/AppContext"
import { useState } from "react"

const AvailabilityInput = () => {
    const {
        availabilityFlag,
        setAvailabilityFlag
    } = useAppContext()

    return (
        <div>
            <label className="sb3-text-sm sb3-font-bold sb3-text-gray-extraDark sb3-space-x-2">
            <input 
                type="checkbox" 
                onChange={() => setAvailabilityFlag(prev => !prev)} 
                checked={!!availabilityFlag} 
                className="focus:sb3-outline-none focus:sb3-ring-0 focus:!sb3-shadow-none !sb3-border-0 focus:!sb3-border-0"
            />
            <span>Show In Stock Only</span></label>
        </div>
    )
}

export default AvailabilityInput