import CompatioAnalytics from "helpers/CompatioAnalytics";
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import {get as _get} from 'lodash'
import { ANCHOR_TYPES, CMPT_CONF_DEFAULT, PLACEHOLDER_IMAGE } from "constants/constants";
import { checkVariantHasBuilder } from "api";
import AppContextWrapper from "contexts";
import ThemeSelector from "pages";
import Utils from "Services/Utils";

type MyProps = {
  type?: string
}

const Anchor = (props: MyProps) => {
  const [isDcShow, setIsDcShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modelId, setModelId] = useState<string>()

  const DcConfigs = _get(window, 'compatioConfig.compatibleProducts.dcConfig');
  const [dcConfigDetails, setDCConfigDetails] = useState((DcConfigs?.[0] as any)?.details);
  const prevFontSize = useRef<string>()
  const analyticsPrev = useRef<any>()

  const IsProductSpecificDC = _get(
    window,
    'compatioConfig.compatibleProducts.isProductSpecificDC'
  ) as boolean|undefined ?? false

  const propsNew: string[] = [];

  const trackAnchorClick = () => {
    CompatioAnalytics.track(
      CompatioAnalytics.events.dcAnchorClick,
      {
        baseFinalProductID: window.compatioConfig?.compatibleProducts?.productSku && decodeURI(window.compatioConfig.compatibleProducts.productSku),
        baseParentProductID: window.compatioConfig?.compatibleProducts?.productSku && decodeURI(window.compatioConfig.compatibleProducts.productSku),
        baseFinalProductName: window.compatioConfig?.compatibleProducts?.productName && decodeURI(window.compatioConfig.compatibleProducts.productName),
        clickAction: CompatioAnalytics.clicks.dcGotoWidget,
      },
      'SmartBuilder3',
      undefined,
      'V1'
    );
  };

  useEffect(() => {
      if (isDcShow == true) {
        let showDataForBuilder =
          IsProductSpecificDC === true
            ? window.compatioConfig?.compatibleProducts?.productSku
              ? propsNew[0]
              : props
            : props;
        CompatioAnalytics.track(
          CompatioAnalytics.events.arsRecShow,
          {
            baseFinalProductID: window.compatioConfig?.compatibleProducts?.productSku && decodeURI(window.compatioConfig.compatibleProducts.productSku),
            baseParentProductID: window.compatioConfig?.compatibleProducts?.productSku && decodeURI(window.compatioConfig.compatibleProducts.productSku),
            baseFinalProductName: window.compatioConfig?.compatibleProducts?.productName && decodeURI(window.compatioConfig.compatibleProducts.productName),
            abCell: 1,
            abFactor: 1,
          },
          'SmartBuilder3',
          '',
          'V1'
        );
      }
    }, [isDcShow]);

  useEffect(() => {
    console.log(!window.compatioConfig?.compatibleProducts?.productSku, !Utils.getMerchantKey())
    if(!window.compatioConfig?.compatibleProducts?.productSku || !Utils.getMerchantKey()) return

    checkVariantHasBuilder(decodeURI(window.compatioConfig.compatibleProducts.productSku), Utils.getMerchantKey() as string)
    .then((res) => {
      setIsDcShow(true)
      setModelId(res.project_id)
    })
    .catch(() => setIsDcShow(false))
  }, [])


  useLayoutEffect(() => {
    const body = document.querySelector('body')
    if(isOpen) {
        if(body) body.style.overflow = "hidden"
        analyticsPrev.current = window.CompatioAnalytics
        window.CompatioAnalytics = CompatioAnalytics
    }
    else{ 
        if(body) body.style.overflow = "auto"
        window.CompatioAnalytics = analyticsPrev.current
    }

    return () => {
        const body = document.querySelector('body')
        if(body) body.style.overflow = "auto"
        window.CompatioAnalytics = analyticsPrev.current
    };
  }, [isOpen])

  useEffect(() => {
    setDCConfigDetails(DcConfigs?.find(each => each.modelId === modelId)?.details)
  }, [modelId])

  if(props.type === ANCHOR_TYPES.BANNER){ 
    // const currentAnchorDetails = DcConfigs?.find(each => each.modelId === window.compatioConfig?.magento?.modelId)?.details
    const defaultBuilders = DcConfigs?.filter(each => each[CMPT_CONF_DEFAULT] === "1")
    return (
      <AppContextWrapper modelId={modelId} isOpen={isOpen} setIsOpen={setIsOpen} blank={true}>
        <div className="sb3-w-full sb3-space-y-2 sb3-contain">
          {
            defaultBuilders?.map((currentAnchorDetails, key) => {
              if(!currentAnchorDetails.details) return <Fragment key={key}/>

              return (
                <div
                  className={`compatio-anchor-sb3 bsb3-order-l sm:sb3-flex sb3-w-full sb3-border sb3-shadow`}
                  key={key}
                >
                  <div className="sb3-flex sb3-justify-center sm:sb3-w-5/12">
                    <img
                      src={currentAnchorDetails.details?.imageUrl ?? PLACEHOLDER_IMAGE}
                      className="sb3-w-full"
                      alt="Build-Fly-Fishing"
                    />
                  </div>
                  
                  <div className="sb3-m-auto sb3-h-full sb3-p-5 sm:sb3-w-7/12 sb3-w-full">
                    <div className="contentset-dc-anchor sb3-space-y-2">
                      <div className="buildouttext-left">
                        <span className="dc-anchor-text sb3-space-y-2">
                          <p className="!sb3-text-lg">
                            {currentAnchorDetails.details?.description
                              ? currentAnchorDetails.details.description
                              : 'Browse compatible categories for your products and other accessories'}
                          </p>
                        </span>
                      </div>

                      <div className="sb3-text-left">
                        <button 
                        onClick={() => {
                          trackAnchorClick()
                        
                          setIsOpen(true);
                          setModelId(currentAnchorDetails.modelId)
                        }}
                        type="button"
                        className="action primary sb3-border sb3-rounded sb3-px-2 sb3-py-1.5 !sb3-text-lg sb3-min-w-max">
                          {currentAnchorDetails.details?.title
                            ? currentAnchorDetails.details.title
                            : 'Build a Outfit'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      
    
      <div>
        <ThemeSelector/>
      </div>
    </AppContextWrapper>
  )
}

if(!isDcShow) return <></>

if(props.type === ANCHOR_TYPES.BUTTON) return (
      <AppContextWrapper modelId={modelId} isOpen={isOpen} setIsOpen={setIsOpen}>
    
        <button
        type="button"
        onClick={() => {
          trackAnchorClick()
        
          setIsOpen(true);
        }}
        className={`action sb3-contain secondary btn btn--full ${!Utils.isShopify() ? "sb3-bg-gray-100 sb3-border-gray-300 sb3-border sb3-rounded sb3-text-xl sb3-px-2 sb3-py-1.5 " : "sb3-bg-black"} sb3-font-semibold !sb3-w-full sb3-mb-[15px] sb3-min-w-max sb3-tocart`}
        >
          {dcConfigDetails && dcConfigDetails.buttonText
            ? dcConfigDetails.buttonText
            : 'Create Your Solution'}
        </button>

      <div>
        <ThemeSelector/>
      </div>
    </AppContextWrapper>
  )

  return (
      <AppContextWrapper modelId={modelId} isOpen={isOpen} setIsOpen={setIsOpen}>
      <div
        className={`compatio-anchor-sb3 sb3-pl-5 sb3-border-l sb3-max-w-[234px] sb3-contain`}
        onClick={() => {
          trackAnchorClick()
        
          setIsOpen(true);
        }}
      >
        <img
          src={dcConfigDetails?.imageUrl ?? PLACEHOLDER_IMAGE}
          className="dc-anchor-ff-image sb3-min-h-36 sb3-aspect-video sb3-object-contain"
          alt="Build-Fly-Fishing"
        />
        <div className="sb3-pt-4">
          <div className="contentset-dc-anchor sb3-space-y-2">
            <div className="buildouttext-left">
              <span className="dc-anchor-text sb3-space-y-2">
                <strong>
                  <span className="sb3-text-[32px] sb3-text-[#7faadb]">
                    {dcConfigDetails?.title
                      ? dcConfigDetails?.title
                      : 'Build It Out'}
                  </span>
                </strong>
                <p className="sb3-text-sm">
                  {dcConfigDetails?.description
                    ? dcConfigDetails.description
                    : 'Browse compatible categories for your products and other accessories'}
                </p>
              </span>
            </div>

            <div className="sb3-text-left builoutbutton-right">
              <button 
              type="button"
              className="action primary anchor btn btn--full sb3-border sb3-rounded sb3-px-2 sb3-py-1.5 sb3-text-xl !sb3-w-full">
                {dcConfigDetails && dcConfigDetails.buttonText
                  ? dcConfigDetails.buttonText
                  : 'BUILD AN OUTFIT'}
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <div>
            <ThemeSelector/>
      </div>
    </AppContextWrapper>
  )
}

export default Anchor