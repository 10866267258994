import { createConnectionApi, createInstanceApi, deleteInstanceApi, dropConnectionApi, fetchModelSpec, setInstanceInput, setInstanceInputs } from "api"
import { Dispatch, SetStateAction, useCallback } from "react"
import { toast } from "react-toastify"
import { TApiRuntimeSpec, TResultProduct, TRuntimeSpec } from "types"
import * as types from "types/contexts/AppContext"
import * as consts from 'constants/constants'
import { getNewSessionIdPreview } from "api/getNewSessionIdPreview"
import { useLocation, useSearchParams } from "react-router-dom"
import AuthManager from "Services/AuthManager"
import { TCart } from "types/Cart"
import Utils from "Services/Utils"
import { isArray } from "lodash"


/**
 * Cart based context 
 */

const useApis = (
    modelSpec: types.TmodelSpec,
    setUiDefn: Dispatch<SetStateAction<types.TuiDefn>>,
    setModelSpec: Dispatch<SetStateAction<types.TmodelSpec>>,
    setRuntimeSpec: Dispatch<SetStateAction<TRuntimeSpec | null>>,
    setCartInfo: Dispatch<SetStateAction<TResultProduct[]>>,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setDataLoading: Dispatch<SetStateAction<boolean>>,
    setDisabledModal: Dispatch<SetStateAction<types.TdisabledModal | null>>,
) => {
    const location = useLocation()
    const [URLSearchParams, ] = useSearchParams()

    const isPreview = location.pathname.includes(consts.PAGES.PREVIEW)

    const loadModelSpec = useCallback(async (setSessionId = true, iter = 0, isReset: boolean = false, modelId?: string, variantList?: string[], blank?: boolean) => {
        let projectId = modelId ?? (isPreview ? URLSearchParams.get('modelId') : URLSearchParams.get('projectId'))
        // const hasSmartBuilder = URLSearchParams.has('smartBuilder');
        // const hasBlank = URLSearchParams.has('blank');
        // const blankValue = URLSearchParams.get('blank');

        // if (blank && window.compatioConfig?.magento?.modelId) {
        //   projectId = window.compatioConfig.magento.modelId
        // }
        let sessionId = (URLSearchParams.get("sessionId") || AuthManager.getSessionId())
        // if(!initialModelId.current) initialModelId.current = projectId

        if((isReset || !modelSpec) && isPreview && projectId) {
          getNewSessionIdPreview(projectId)
          .then((res) => {
              setModelSpec(res[consts.MODEL_SPEC])
              setRuntimeSpec(Utils.formatRuntimeSpec(res[consts.RUNTIME_SPEC]))
              setUiDefn(res[consts.UI_SPEC])
          })
          .catch((err) => {
              toast.error(err.message)
          })
          .finally(() => {
              setLoading(false)
          })
          return
        }

        let isSameProduct = true // whether the variant is same
        if(isArray(variantList) && variantList?.length > 0 
         && JSON.stringify(variantList[0]) !== AuthManager.getProductId()) {
          AuthManager.setProductId(variantList[0])
          isSameProduct = false
        }

        fetchModelSpec(Utils.getMerchantKey(), !blank && setSessionId && isSameProduct, projectId, false, sessionId, !isPreview, variantList)
        .then((res) => {
            setModelSpec(res[consts.MODEL_SPEC])
            setRuntimeSpec(Utils.formatRuntimeSpec(res[consts.RUNTIME_SPEC]))
            setUiDefn(res[consts.UI_SPEC])
            setCartInfo(res[consts.CART_INFO] ?? [])
        })
        .catch((err) => {
            toast.error(err.message)
        })
        .finally(() => {
            setLoading(false)
        })
    }, [])

    const updateInstanceInput: types.TupdateInput = (
        fieldId,
        choice,
        instanceId,
        forced = false,
        disabled = false,
        deselect = false,
        choiceData,
      ) => {
        setDataLoading(true)
        console.log(choiceData)
        setInstanceInput(
          fieldId,
          choice,
          instanceId,
          forced,
          disabled,
          false
        )
        .then((res: any) => {
          if(res[consts.RES_CONFLICT_FLAG] && choiceData) 
            return setDisabledModal({
              fieldId, 
              items: choice as string[],
              item: choiceData, 
              instanceId, 
              name: choiceData[consts.CHOICE_NAME], 
              fields: res[consts.RES_CONFLICT_CHOICES]
            })
          else {
            setDisabledModal(null)
          }
          
          setRuntimeSpec(Utils.formatRuntimeSpec(res[consts.RUNTIME_SPEC])) 
          if(res[consts.CART_INFO]?.length>0) setCartInfo(res[consts.CART_INFO] ?? [])
        })
        .catch((err) => {
          toast.error(err.message)
        })
        .finally(() => {
          setDataLoading(false)
        })
      }

      const updateInstanceInputs: types.TupdateInputs = (
        value,
        instanceId,
        forced = false,
        disabled = false,
        deselect = false,
        choiceData,
      ) => {
        setDataLoading(true)
  
        setInstanceInputs(
          value,
          instanceId,
          forced,
          disabled,
          false
        )
        .then((res: any) => {
          if(res[consts.RES_CONFLICT_FLAG] && choiceData) 
            setDisabledModal(null)
          
          setRuntimeSpec(Utils.formatRuntimeSpec(res[consts.RUNTIME_SPEC])) 
          if(res[consts.CART_INFO]?.length>0) setCartInfo(res[consts.CART_INFO] ?? [])
        })
        .catch((err) => {
          toast.error(err.message)
        })
        .finally(() => {
          setDataLoading(false)
        })
      }
  
      const createInstance: types.TCreateInstance = (subModelId, values) => {
        if(!subModelId) return
  
        setDataLoading(true)
        createInstanceApi(
          subModelId,
          values
        )
        .then((res: any) => {
          setRuntimeSpec(Utils.formatRuntimeSpec(res[consts.RUNTIME_SPEC]))
        })
        .catch((err) => {
          toast.error(err.message)
        })
        .finally(() => {
          setDataLoading(false)
        })
      }
  
      const deleteInstance: types.TDeleteInstance = (instanceId) => {
        if(!instanceId) return
  
        setDataLoading(true)
        deleteInstanceApi(
          instanceId
        )
        .then((res: any) => {
          setRuntimeSpec(Utils.formatRuntimeSpec(res[consts.RUNTIME_SPEC]))
        })
        .catch((err) => {
          toast.error(err.message)
        })
        .finally(() => {
          setDataLoading(false)
        })
      }
  
  
      const createConnection: types.TCreateConnection = (
        connectorId, // parent
        connectionId, // child
      ) => {
        setDataLoading(true)
        createConnectionApi(
          connectorId,
          connectionId
        )
        .then((res: any) => {
          setRuntimeSpec(Utils.formatRuntimeSpec(res[consts.RUNTIME_SPEC]))
        })
        .catch((err) => {
          toast.error(err.message)
        })
        .finally(() => {
          setDataLoading(false)
        })
      }
  
      const dropConnection: types.TCreateConnection = (
        connectorId, // parent
        connectionId, // child
      ) => {
        setDataLoading(true)
        dropConnectionApi(
          connectorId,
          connectionId
        )
        .then((res: any) => {
          setRuntimeSpec(Utils.formatRuntimeSpec(res[consts.RUNTIME_SPEC]))
        })
        .catch((err) => {
          toast.error(err.message)
        })
        .finally(() => {
          setDataLoading(false)
        })
      }

    return {
        loadModelSpec,
        updateInstanceInput,
        createInstance,
        deleteInstance,
        createConnection,
        dropConnection,
        updateInstanceInputs
    }
}

export default useApis