import {ChangeEventHandler, useEffect, useState} from "react";
import Title from "../Header/Title";
import Utils from "Services/Utils";
import { useAppContext } from "contexts/AppContext";
import { PAGE_LAYOUT, PAGE_LAYOUTS } from "constants/constants";

type MyProps = {
    name: string
    value?: string|number
    onChange: (_value?: number) => void
    placeholder?: string
    disabled?: boolean
    required?: boolean
    error?: string
    min?: number|string,
    max?: number|string,
    increment?: number|string
    description?: string
    info?: string
}

const NumberField = (props: MyProps) => {
    const {activePage} = useAppContext()
    const [_value, setValue] = useState("")
    const [error, setError] = useState<string|null>(null)

    useEffect(() => {
        console.log(props.value, props.name)
        setValue(props.value + "" ?? "")
    }, [props.value])

    useEffect(() => {
        if(_value === "") return setError(null)
        const _error = Utils.validateNumericField(_value, {min: props.min, max: props.max, increment: props.increment})
        setError(_error)
    }, [_value])

    const submit = () => {
        const _error = Utils.validateNumericField(_value, {min: props.min, max: props.max, increment: props.increment})
        setError(_error)
        if(!_error && props.value !== Number(_value)) props.onChange((_value.trim() !== '') ? Number(_value) : undefined)
    }

    const handleBlur: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        submit()
    }

    const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault()
            submit()
        }
    }

    return ( 
        <div className="sb3-space-y-2">
            <Title title={props.name} required={props.required} description={props.description} info={props.info}/>
            <div
            //  className={activePage?.[PAGE_LAYOUT] === PAGE_LAYOUTS.GUIDED_SELLING ? "grid md:grid-cols-3 lg:grid-cols-4 sm:grid-cols-2 grid-cols-1" : ""}
             >
           
                <input disabled={props.disabled}  min={props.min ?? undefined} max={props.max ?? undefined}  onWheel={(e) => (e.target as any).blur()} onBlur={handleBlur} onKeyDown={handleKeyPress} onChange={(e) => setValue(e.target.value)} value={_value} type="number" id="number-input" placeholder={props.placeholder && ''}
                        className={`disabled:sb3-bg-disabled sb3-bg-white sb3-border sb3-border-gray-300 sb3-text-gray-900 sb3-text-sm sb3-rounded-none focus:sb3-ring-0 focus:sb3-outline-none sb3-block ${activePage?.[PAGE_LAYOUT] === PAGE_LAYOUTS.GUIDED_SELLING ? '!sb3-w-60' : 'sb3-w-full'} sb3-max-w-full sb3-p-2.5`}/>

                {error &&
                    <p id="helper-text-explanation" className="sb3-ml-2 sb3-text-sm sb3-text-red-500">{error}</p>}
            </div>
        </div>
    )
};

export default NumberField