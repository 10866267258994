import Utils from "Services/Utils"
import { HiOutlineInformationCircle } from "react-icons/hi"
import { Tooltip } from "react-tooltip"

/**
 * tooltip with info icon
 * 
 * @param props 
 * @param {string} props.message - message for the tooltip
 * @returns 
 */
const TooltipInfo = (props: {message: string}) => {
    const tooltipId = Utils.generate_file_name(5)
// @todo resize observer error
    return (
        <span>
            <HiOutlineInformationCircle className="sb3-text-primary !sb3-text-lg" data-tooltip-id={`delta-table-${tooltipId}`}/>
            <Tooltip 
                className={` !sb3-text-white sb3-break-normal sb3-z-10 sb3-p-0.5 sb3-whitespace-normal sb3-absolute sb3-shadow-lg`}
                id={`delta-table-${tooltipId}`} 
                place="right"
                style={{background: "white",  opacity: 1, color: "black"}}
                border="1px solid #c5c5ca" 
                opacity={1}
                events={['click']}
                >
                    
                    <div
                     className="sb3-text-black  sb3-max-w-[300px] sb3-max-h-[200px] !sb3-overflow-auto sb3-pointer-events-auto" 
                    dangerouslySetInnerHTML={{ __html: props.message }}>
                    </div>
            </Tooltip>
        </span>
    )
}

export default TooltipInfo